<template>
    <div class="vote" @click="$route.params.account ? $router.push(`/vote/${$route.params.account}/${vote.id}`) : $router.push(`/vote/${vote.id}`)">
        <div class="row">
            <!-- <router-link :to="`/vote/${vote.id}`" class="btn btn-primary" style="margin-right: auto;" v-if="!isNaN(Number(index))">{{ label || voteData.topic }}</router-link> -->
            <h3 style="margin-right: auto;">{{ vote.layer === 2 ? 'L2' : 'L1' }} {{ label || voteData.topic }}</h3>
            <div v-if="vote.topic === 'R' && vote.topic_detail === 'D'">
                <DelayTime :time="voteData.value" style="scale: 0.9;"/>
            </div>
            <h2 v-else-if="vote.topic === 'R' && vote.topic_detail === 'R'">
                {{ voteData.value }}
            </h2>
            <h2 v-else-if="vote.topic === 'H' || vote.topic === 'S'">
                {{ vote.topic_detail }}
            </h2>
            <!-- <h3 style="margin-right: auto;">{{ voteData.label || voteData.topic }}</h3> -->
            <!-- <h3 style="width: 60px; margin-right: 20px;">{{ voteData.value }}</h3> -->
            <!-- <router-link :to="`/vote/${vote.id}`" class="btn btn-primary" v-if="!isNaN(Number(index))">View</router-link> -->
        </div>
        <div v-if="vote.topic === 'S'" style="text-align: left;">
            <code>{{ voteData.value === 'rrrrrrrrrrrrrrrrrrrrrhoLvTp' ? 'Remove/Vacant Seat' : voteData.value }}</code>
        </div>
        <code v-else-if="vote.topic === 'H'">
            {{ 'Hash' || vote.raw }}
        </code>
        <!-- <div v-else-if="vote.topic === 'R' && vote.topic_detail === 'D'">
            <DelayTime :time="voteData.value"/>
        </div>
        <div v-else-if="vote.topic === 'R' && vote.topic_detail === 'R'">
            {{ voteData.value }}
        </div> -->
        <hr>
        <ProgressBar :progress="voteData.progress" :threshold="voteData.threshold"/>
        <!-- <span>{{ voters }}/{{ participants }}</span> -->
    </div>
</template>

<script setup>
import { ref, computed, defineProps, inject } from 'vue'
import parser from '../plugins/parse-number'
import ProgressBar from './ProgressBar.vue'
import DelayTime from './DelayTime.vue'
import { useRoute } from 'vue-router'
const store = inject('store')
const route = useRoute()

const props = defineProps({
    vote: {
        type: Object,
        required: true
    },
    index: Number
})
const participants = ref(store.getters.getGovernanceState(route.params.account)?.state?.memberCount)
const voters = ref(props.vote.voters?.length)

const progress = computed(() => {
    return Number(voters.value / participants.value)
})

const label = computed(() => {
    switch(props.vote.topic) {
        case 'R': {
            let str = 'Reward'
            switch(props.vote.topic_detail) {
                case 'R':
                    return str + ' Rate'
                case 'D':
                    return str + ' Delay'
            }
            return str
        }
        case 'S':
            return 'Seat'
        case 'H':
            return 'Hook'
    }
    return null
})

const voteData = computed(() => {
    const parsed = parser.parseTopic(props.vote)
    const state = {
        topic: `${props.vote.topic}${props.vote.topic_detail}`,
        votes: voters.value,
        progress: progress.value,
        threshold: props.vote?.topic === 'S' ? 0.8 : 1,
        raw: props.vote
    }
    return { ...state, ...parsed }
}) 
</script>

<style scoped>
h3 {
    margin: 0;
    padding: 0;
}
.vote {
    padding: 20px;
    background-color: var(--var-bg-color-secondary);
    border-radius: 17px;
    margin: 20px 0;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    cursor: pointer;
}
.vote .btn {
    margin: 0;
}
.row {
    display: flex;
    flex-direction: row;
    align-items: center;
}
hr {
    margin-top: 16px;
    margin-bottom: 20px;
}
</style>
