<template>
    <div class="container view ">
        <NavHeader />
        <div v-if="table">
            <h2>Table {{ position?.table }} Votes</h2>
            <code>{{ `${table.slice(0, 5)}...${table.slice(-5)}` }}</code>
        </div>
        <div v-else>
            <h2>Governance Votes</h2>
            <code>Genesis: {{ `${genesis.slice(0, 5)}...${genesis.slice(-5)}` }}</code>
        </div>
        <VotesListPage :votes="votes" v-if="votes"/>
    </div>
</template>

<script setup>
import NavHeader from '@/components/NavHeader.vue'
import VotesListPage from '@/components/VotesListPage.vue'

import { ref, computed, inject } from 'vue'
import { useRoute } from 'vue-router'
const route = useRoute()
const store = inject('store')

const table = ref(route.params.account)
const genesis = ref(store.state.genesisAccount)

const votes = computed(() => {
    if(!table.value) {
        return store.getters.getVotes()
    } else {
        return store.getters.getVotes(table.value)
    }
})

const position = computed(() => {
    if(table.value) return store.getters.getSeatPosition(table.value)
    else return null
})

</script>