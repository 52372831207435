<template>
  <div class="container view" @click="$router.push('/votes')">
     Loading...
  </div>
</template>

<script setup>
</script>

<style>

</style>
