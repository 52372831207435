<template>
    <nav>
        <router-link to="/votes">
            <svg width="178" height="32" viewBox="0 0 178 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M97.5702 31.6826H106.819L110.79 19.6613L101.661 20.4504L97.5702 31.6826ZM115.043 12.2693L114.137 9.52725L113.166 12.4672L104.225 13.4102L109.109 0H119.255L130.749 31.6826H121.455L117.299 19.0986L117.334 19.0956L115.057 12.2678L115.043 12.2693ZM15.6527 22.7839L15.6241 22.7424L10.3712 31.6854H0.359175L10.6406 15.4621L0 0.0028202H10.3712L16.3425 8.72116L21.4607 0.0028202H31.4727L21.326 15.9565L25.3003 21.7339L15.6731 22.7492L15.6527 22.7839ZM18.9643 27.5989L21.775 31.6854H32.1461L28.7529 26.7528L18.9643 27.5989ZM35.004 26.2124L33.0108 31.6854H42.2595L44.3337 25.4059L35.004 26.2124ZM52.6558 18.8489L49.5777 9.53007L46.2774 19.5215L37.0878 20.4907L44.5492 0.0028202H54.6959L61.2057 17.9472L52.6558 18.8489ZM54.5305 24.5245L56.8959 31.6854H66.1895L63.3162 23.765L54.5305 24.5245ZM67.7107 23.3851V31.6854H76.5105V22.6245L67.7107 23.3851ZM76.5166 22.6221L87.2409 21.7092V31.6854H96.0407V0.0028202H87.2409V15.1835L76.5105 16.2992V0.0028202H67.7107V17.2611L76.5166 16.3324V22.6221ZM138.65 9.79867V0.0028202H129.895V10.7149L138.65 9.79867ZM138.65 17.2782V18.3832C138.65 20.0909 139.039 21.4092 139.817 22.3379C140.625 23.2667 141.838 23.7311 143.454 23.7311C145.07 23.7311 146.282 23.2667 147.09 22.3379C147.929 21.3792 148.348 20.061 148.348 18.3832V16.4514L157.147 15.7011V18.3832C157.147 21.2893 156.534 23.776 155.307 25.8432C154.079 27.8805 152.403 29.4234 150.278 30.472C148.183 31.4907 145.848 32 143.274 32C140.7 32 138.395 31.4907 136.36 30.472C134.355 29.4234 132.768 27.8805 131.601 25.8432C130.464 23.806 129.895 21.3193 129.895 18.3832V18.0247L138.65 17.2782ZM157.147 7.86279L148.348 8.78374V0.0028202H157.147V7.86279Z" fill="white"/>
                <path d="M175.994 9.19814C176.64 9.49328 176.715 10.3572 176.129 10.7532L167.035 16.8988C166.449 17.2948 165.642 16.9258 165.582 16.2347L164.652 5.50839C164.593 4.81727 165.325 4.3223 165.971 4.61745L175.994 9.19814Z" fill="white"/>
            </svg>
        </router-link>
        <!-- <a class="btn btn-primary">New Topic</a> -->
        <h3 v-if="!voteMode">View Only</h3>
    </nav>
    <div class="account-header">
        <h3 v-if="voteMode">Seat {{ position }}</h3>
        <h3 v-else>Viewer</h3>
        <code>{{ `${account.slice(0, 5)}...${account.slice(-5)}` }}</code>
        <div style="display: flex;">
            <router-link :to="$route.path === '/votes' ? '/state' : '/votes'" class="btn" style="margin-left: auto; padding: 0;">
                <h3>{{ $route.path === '/votes' ? 'Seats' : 'Votes' }}</h3>
                <img src="../assets/chevron-left.svg" style="transform: rotate(180deg); margin: 0 10px;">
            </router-link>
        </div>
    </div>
</template>

<script setup>
import { computed, inject } from 'vue'
const store = inject('store')

const voteMode = computed(() => {
    return store.getters.voteMode
})

const account = computed(() => {
    return store.state.account
})

const position = computed(() => {
    const pos = store.getters.getSeatPosition(account.value)
    if(!pos || pos == null) return null
    else return pos.layer === 1 ? `${pos.table}` : `${pos.table}-${pos.seat}`
})
</script>

<style scoped>
nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.account-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.15);
    margin-bottom: 20px;
    padding-left: 9px;
}
.account-header > * {
    flex: 1;
}
.account-header > *:first-child {
    text-align: left;
}
.account-header > *:last-child {
    text-align: right;
}
</style>
