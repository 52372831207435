/* eslint-disable */

const minMantissa = 1000000000000000n
const maxMantissa = 9999999999999999n
const minExponent = -96
const maxExponent = 80

// const getMantissaAndExponent = (number) => {
//     if (number === 0) return { mantissa: 0, exponent: 0 }

//     const log = Math.floor(Math.log10(Math.abs(number)));
//     let exponent = log - 2 - 15;

//     if(Number(number) < 1) number = number.replace(/^0*[.,]0*/, '')

//     const digitsToPad = 16 - number.length
//     const paddedBigIntString = `${number}${'0'.repeat(digitsToPad)}`
//     // eslint-disable-next-line
//     let mantissa = BigInt(paddedBigIntString);
//     return { mantissa, exponent };
// }

const floatToXfl = (fl) => {
    let e = 0
    let d = '' + parseFloat('' + fl)
    d = d.toLowerCase()
    let s = d.split('e')
    if (s.length == 2) {
      e = parseInt(s[1])
      d = s[0]
    }
    s = d.split('.')
    if (s.length == 2) {
      d = d.replace('.', '')
      e -= s[1].length
    } else if (s.length > 2) d = BigInt(0).toString()
    return make_xfl(e, d)
}

const make_xfl = (exponent, mantissa) => {
    // convert types as needed
    if (typeof(exponent) != 'bigint')
        exponent = BigInt(exponent);

    if (typeof(mantissa) != 'bigint')
        mantissa = BigInt(mantissa);

    // canonical zero
    if (mantissa == 0n)
        return 0n;

    // normalize
    let is_negative = mantissa < 0;
    if (is_negative)
        mantissa *= -1n;

    while (mantissa > maxMantissa)
    {
        mantissa /= 10n;
        exponent++;
    }
    while (mantissa < minMantissa)
    {
        mantissa *= 10n;
        exponent--;
    }

    // canonical zero on mantissa underflow
    if (mantissa == 0)
        return 0n;

    // under and overflows
    if (exponent > maxExponent || exponent < minExponent)
        return -1; // note this is an "invalid" XFL used to propagate errors

    exponent += 97n;

    let xfl = (!is_negative ? 1n : 0n);
    xfl <<= 8n;
    xfl |= BigInt(exponent);
    xfl <<= 54n;
    xfl |= BigInt(mantissa);

    return xfl;
}

function get_exponent(xfl) {
    if (xfl < 0n)
        throw "Invalid XFL";
    if (xfl == 0n)
        return 0n;
    return ((xfl >> 54n) & 0xFFn) - 97n;
}

function get_mantissa(xfl) {
    if (xfl < 0n)
        throw "Invalid XFL";
    if (xfl == 0n)
        return 0n;
    return xfl - ((xfl >> 54n)<< 54n);
}

function is_negative(xfl) {
    if (xfl < 0n)
        throw "Invalid XFL";
    if (xfl == 0n)
        return false;
    return ((xfl >> 62n) & 1n) == 0n;
}

const to_string = (xfl) => {
    if (xfl < 0n)
        throw "Invalid XFL";
    if (xfl == 0n)
        return "<zero>";
    return `${is_negative(xfl) ? "-" : ""}${get_mantissa(xfl)}e${get_exponent(xfl)}`
}

export default {
    floatToXfl,
    make_xfl,
    to_string
}