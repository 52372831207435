<template>
  <router-view />
</template>

<script>
import xapp from './plugins/xapp.js'
import client from './plugins/ws-client.js'

import GovParser from './plugins/governance-hook.js'

export default {
  data() {
    return {
      config: {
        genesisAccount: this.$store.state.genesisAccount,
        genesisNamespace: '0000000000000000000000000000000000000000000000000000000000000000',
        seatCount: 20,
        hookPositions: 10,
        accountMetaExpirationInSeconds: 300
      }
    }
  },
  async mounted() {
    try {
      this.$store.dispatch('initXahau', { networkId: 21338, account: 'rLjrBkBUSRtZJnFfZAbX4SFQSpkk2HcpX8' })
      client.connect('wss://dev.xahau.network')

      this.setJwt()
      const l1 = await this.getNameSpace(this.config.genesisAccount)
      this.$store.dispatch('setGovernance', l1)
      console.log('l1', l1)

      this.$router.push('/votes')

      l1.seats.forEach(async seat => {
        const l2 = await this.getNameSpace(seat.account)
        if(l2) this.$store.dispatch('setGovernanceLayer', l2)
      })
    } catch(e) {
      console.log(e)
    }
    this.loading = false
  },
  methods: {
    async setJwt() {
      try {
        const token = await this.$xapp.getOttData()
        const jwt = await this.$xapp.getJwt()
        xapp.setJWT(jwt)
        this.$store.dispatch('initXahau', { networkId: this.destinationChain(token.nodetype), account: token.account })
      } catch(e) {
        console.log('Todo ignore...')
      }
    },
    destinationChain(type) {
      switch(type) {
          case 'XAHAU':
            return 21337
          case 'XAHAUTESTNET':
            return 21338
          default:
            throw new Error('Destination chain is not supported ' + type)
        }
      },
    async getNameSpace(account) {
      const res = await client.send({
        command: 'account_namespace',
        account: account,
        namespace_id: this.config.genesisNamespace
      })
      return GovParser.parseData(res)
    }
  },
  beforeUnmount() {
    client.close()
  }
}
</script>

<style>
html, body {
  height: 100%;
  width: 100%;
  margin: 0;
  overscroll-behavior-y: none;
  position: fixed;
  overflow: hidden;
  background-color: rgb(12	1	64);
  color: white;
}
:root {
  --var-bg-color: rgb(12	1	64);
}
:root,
:root.dark {
  /* Dark */
  --blue:rgb(10, 132, 255);
  --green:rgb(48, 209, 88);
  --red:rgb(255, 69, 58);
  --orange:rgb(255, 159, 10);
  --grey:rgb(142, 142, 147);
  --grey2:rgb(99, 99, 102);
  --grey3:rgb(72, 72, 74);
  --grey4:rgb(58, 58, 60);
  --grey5:rgb(44, 44, 46);
  --grey6:rgb(28, 28, 30);

  --var-primary: var(--blue);
  --var-input-bg-color: #494e61;
  --var-bg-color-secondary: #3E3469;
  --var-txt-color: white;

  /* todo fix var names */
  --var-secondary: #2e313c;
  --var-border:rgb(67, 67, 67);
  --var-backdrop: 0, 0, 0;
}
:root.black {
  --var-input-bg-color: rgb(38, 38, 38);
  --var-bg-color-secondary: var(--grey4);
  --var-secondary: rgb(28, 28, 28);
}
:root.light {
  /* Light */
  --blue: rgba(0, 122, 255);
  --green: rgba(52, 199, 89);
  --orange: rgba(255, 149, 0);
  --red: rgb(255, 59, 48);
  --grey: rgba(142, 142, 147);
  --grey2: rgba(174, 174, 178);
  --grey3: rgba(199, 199, 204);
  --grey4: rgba(209, 209, 214);
  --grey5: rgba(229, 229, 234);
  --grey6: rgba(242, 242, 247);

  --var-primary: var(--blue);
  --var-bg-color-secondary: var(--grey4);
  --var-txt-color: black;
  --var-secondary: var(--grey5);
  --var-border:rgb(39, 39, 39);
  --var-backdrop: 0, 0, 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: var(--var-txt-color);
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.container {
  display: flex;
  flex-direction: column;
  overflow: auto;
  flex-grow: 1;
}
.container.view {
  padding: 0 10px;
  padding-top: 10px;
  padding-bottom: 30px;
}
.btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: 40px;
  height: 40px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;
  font-size: 1rem;
  text-align: center;
  color: var(--var-white);
  padding: 0 10px;
  margin: 10px 0;
}
.btn-primary {
  background-color: var(--var-primary);
}
.btn-secondary {
  background-color: var(--var-bg-color-secondary);
}
.btn-success {
  background-color: var(--green);
}
.btn-danger {
  background-color: var(--red);
}
ul,li {
  list-style: none;
  margin: 0;
  padding: 0;
}
li.item {
  display: flex;
  flex-direction: row;
  border: 1px;
  border-radius: 5px;
  background-color: #282828;
  margin: 10px 0;
  padding: 10px;
}
hr {
  border: none;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
}
a {
  text-decoration: none;
  user-select: none;
  cursor: pointer;
}
h1,h2,h3,h4,h5,h6,p {
  margin: 0;
}
.p-box {
 padding: 20px 10px;
 /* background-color: var(--grey);
 border: 2px solid var(--var-border);
 border-radius: 15px; */
}
.push-top {
  margin-top: auto;
}
.push-left {
  margin-left: auto;
}
</style>
