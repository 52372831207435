<template>
    <div class="btn-tabs-row">
        <a v-for="option in options" @click="selectedTopic = option.value" :key="option.value" class="btn" :class="{ 'btn-primary': selectedTopic === option.value, 'btn-secondary': selectedTopic !== option.value }">{{ option.label }}</a>
    </div>
    <ul>
        <GovernanceVote v-for="(vote, index) in governanceVotes" :vote="vote" :index="index" :key="vote"/>
        <h4 v-if="governanceVotes?.length <= 0">{{ selectedTopic === '*' ? 'No votes' : 'No votes for this topic' }}</h4>
    </ul>
</template>

<script>
import GovernanceVote from './GovernanceVote.vue'

export default {
    components: { GovernanceVote },
    props: {
        votes: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            options: [{ value: '*', label: 'All Topics' }, { value: 'S', label: 'Seats' }, { value: 'R', label: 'Rewards' }, { value: 'H', label: 'Hooks' }],
            selectedTopic: '*'
        }
    },
    computed: {
        governanceVotes() {
            return this.votes.filter(vote => {
                return (vote.topic === this.selectedTopic || this.selectedTopic === '*') && vote?.current === false
            })
        }
    }
}
</script>

<style>
.btn-tabs-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
}
.btn-tabs-row > .btn {
  width: 20%;
  justify-content: center;
  margin: auto;
  margin-bottom: 10px;
  min-width: 70px;
}
.btn-tabs-row > .btn:first-child {
  width: 87%;
}
</style>
