/* eslint-disable */
import { Buffer } from 'buffer';
import crypto from 'crypto';
const R_B58_DICT = 'rpshnaf39wBUDNEGHJKLM4PQRST7VWXYZ2bcdeCg65jkm8oFqi1tuvAxyz';
import baseX from 'base-x';

const base58 = baseX(R_B58_DICT);

const encode = (account_id_hex) => {
    const account_id = Buffer.from(account_id_hex, 'hex');
    const address_type_prefix = Buffer.from([0x00]);
    const payload = Buffer.concat([address_type_prefix, account_id]);
    const chksum_hash1 = crypto.createHash('sha256').update(payload).digest();
    const chksum_hash2 = crypto.createHash('sha256').update(chksum_hash1).digest();
    const checksum =  chksum_hash2.subarray(0,4);
    const dataToEncode = Buffer.concat([payload, checksum]);
    const address = base58.encode(dataToEncode);
    return address
}

const decode = (encoded_address) => {
    const data = Buffer.from(base58.decode(encoded_address));
    // Extract payload and checksum from the data
    const payload = data.subarray(0, -4);
    // const checksum = data.slice(-4);
    // Verify the checksum
    // const chksum_hash1 = crypto.createHash('sha256').update(payload).digest();
    // const chksum_hash2 = crypto.createHash('sha256').update(chksum_hash1).digest();
    // const calculatedChecksum = chksum_hash2.slice(0, 4);
    // Extract account ID from the payload
    const account_id_hex = payload.subarray(1).toString('hex'); // Assuming the first byte is the address type prefix
    return account_id_hex;
}

export default {
    encodeAccount: encode,
    decodeAccount: decode
}