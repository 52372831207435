const parseSeconds = (str) => {
    let seconds = parseInt(Number(str));
    const days = Math.floor(seconds / (3600 * 24));
    seconds %= 3600 * 24;
    const hours = Math.floor(seconds / 3600);
    seconds %= 3600;
    const minutes = Math.floor(seconds / 60);
    return { d: days, h: hours, m: minutes, s: seconds % 60 };
}
const parseRewardRate = (str) => {
    const num = Number(str)
    return `${ (num * 100).toFixed(2)}%`
}

const parseTopic = (vote) => {
    if(!Object.hasOwn(vote, 'topic')) throw new Error('Not a valid vote got: ' + typeof vote)
    switch(vote.topic) {
        case 'R':
            switch(vote.topic_detail) {
                case 'R':
                    return {
                        label: 'Reward Rate',
                        value: parseRewardRate(vote.data)
                    }
                case 'D':
                    return {
                        label: 'Reward Delay',
                        value: parseSeconds(vote.data)
                    }
            }
            return 'Reward'
        case 'S':
            return {
                label: `Seat #${vote.topic_detail}`,
                value: vote.data
            }
        case 'H':
            return {
                label: `Hook position ${vote.topic_detail}`
            }
        default:
            return {
                label: `${vote.topic} + ${vote.topic_detail}`
            }
    }
}

export default {
    parseSeconds,
    parseRewardRate,
    parseTopic
}