/* eslint-disable */
// import { convertStringToHex, decodeAccountID } from '@transia/xrpl'
import xrpl from '../plugins/xrpl/encodeAddress'
import xfl from '../plugins/xrpl/xfl'

// Function to decode a hex string to human-readable format
const hexToBytes = hexString => {
    if (hexString.length % 2 !== 0) {
        throw new Error('Hex string length must be even');
      }
    
      const byteArray = [];
      for (let i = 0; i < hexString.length; i += 2) {
        byteArray.push(parseInt(hexString.substr(i, 2), 16));
      }
    
      return byteArray;
}

function hexToDecimal(hexString) {
    // Remove any leading "0x" or "0X" if present in the input string
    hexString = hexString.replace(/^0x/i, "");
  
    // Parse the hexadecimal string to a decimal number
    const decimalNumber = parseInt(hexString, 16);
  
    return decimalNumber;
}

const decimalToHex = (decimalNumber) => {
    // Ensure the input is a valid decimal number
    if (isNaN(decimalNumber) || decimalNumber < 0 || !Number.isInteger(decimalNumber)) {
        throw new Error("Input must be a non-negative integer");
    }

    // Convert decimal number to hexadecimal string
    let hexString = decimalNumber.toString(16);

    // Add padding if the length of the hexadecimal string is 1
    if (hexString.length === 1) {
        hexString = `0${hexString}`;
    }
    return hexString;
}

const xflHexToString = (hex) => {
    const buffer = Buffer.from(hexToBytes(hex))
    const integerValue = BigInt(buffer.readUIntLE(0, 8))
    const stringValue = xfl.to_string(integerValue)
    return stringValue === '<zero>' ? '0' : stringValue
}

const convertXflToHex = (value) => {
    const buffer = Buffer.alloc(8)
    buffer.writeBigUInt64LE(value)
    const res = buffer.toString('hex')
    return res.padStart(16, '0')
}

// Function to interpret different types of state entries
function interpretHookStateEntry(key, value) {
    // if(/^(0[0-9]|1[0-9])$/.test(key)) return { type: 'seat', account: value.length === 40 ? xrpl.encodeAccount(value) : null, position: key }
    if(key.length == 40) return { type: 'seat', account: xrpl.encodeAccount(key), position: hexToDecimal(value) }
    switch (key) {
        // Singleton state entries
        case "4D43":
            return { type: 'state', state: 'memberCount', value: hexToDecimal(value) };
        case "5252":
            return { type: 'state', state: 'rewardRate', value: xflHexToString(value) };
        case "5244":
            return { type: 'state', state: 'rewardDelaySeconds', value: xflHexToString(value) };
    }
    switch(key.slice(0, 2)) {
        // 43 === C count, 56 === V vote
        case '43': {
            const voteData = hexToBytes(key)
            const count = {
                type: 'count',
                topic: String.fromCharCode(voteData[1]),
                topic_detail: voteData[2] === 82 || voteData[2] == 68 ? String.fromCharCode(voteData[2]) : voteData[2],
                data: key.replace(/^(00)+/, '').length === 40 ? xrpl.encodeAccount(key.replace(/^(00)+/, '')) : key.slice(22),
                count: hexToDecimal(value)
            }
            return count
        }
        case '56': {
            const voteData = hexToBytes(key)
            const topic = String.fromCharCode(voteData[1])
            const topic_detail = voteData[2] === 82 || voteData[2] == 68 ? String.fromCharCode(voteData[2]) : voteData[2]
            const voter = key.slice(22).replace(/^(00)+/, '').length === 40 ? xrpl.encodeAccount(key.slice(22).replace(/^(00)+/, '')) : key.slice(22)
            // 22 = 8 bytes padding + 4 bytes from above values - 1 to start at 0, * 2 number of chars for a byte
    
            const vote = {
                type: key.slice(0, 2) === '56' ? 'vote' : 'count',
                topic: topic,
                topic_detail: topic_detail,
                layer: voteData[3],
                voter: voter,
                raw: {
                    topic: key.slice(2,4),
                    topic_detail: key.slice(4,6),
                    vote_data: value
                },
                bytes: {
                    key: voteData,
                    value: hexToBytes(value) // remove for performance
                },
                hex: {
                    key,
                    value
                }
            }
    
            switch(topic) {
                case 'S':
                    return {
                        ...vote,
                        data: value.length === 40 ? xrpl.encodeAccount(value) : value,
                        account: value.length === 40 ? xrpl.encodeAccount(value) : undefined
                    }
                case 'R':
                    return {
                        ...vote,
                        data: topic_detail === 'R' || topic_detail === 'D' ? xflHexToString(value) : value
                    }
                case 'H':
                    // todo add hook data & postion
                    return {
                        ...vote
                    }
                default:
                    return vote
            }
        }
        default:
            return { type: 'unkown', key, value }
    }
}

// Interpret the HookStateData and create a new array of objects
const parseData = (json) => {
    if(!Object.hasOwn(json, 'namespace_entries')) return null
    const entries = json.namespace_entries
    if(!entries || entries.length < 1) throw new Error('Data cannnot be ' + json)

    const governance = {
        account: json.account,
        votes: [],
        counts: [],
        seats: [],
        state: {
            memberCount: null,
            rewardRate: null,
            rewardDelaySeconds: null
        }
    }
    entries.forEach((item, index) => {
        const key = item.HookStateKey.replace(/^(00)+/, '')
        const result = interpretHookStateEntry(key, item.HookStateData)
        switch(result?.type) {
            case 'state':
                governance.state[result.state] = result.value
                break
            case 'seat':
                governance.seats[result.position] = {
                    account: result.account,
                    position: result.position,
                    votes: []
                }
                // governance.seats.push({
                //     account: result.account,
                //     position: result.position,
                //     votes: []
                // })
                break
            case 'vote':
                governance.votes.push({ id: index, ...result })
                break
            case 'count':
                governance.counts.push(result)
                break
        }
    });
    // governance.seats = governance.seats.sort((a, b) => a.position - b.position)
    const votes = governance.votes.reduce((filteredVotes, vote) => {
        const i = governance.seats.findIndex(seat => seat.account === vote.voter);
        if (i >= 0) {
            governance.seats[i].votes.push(vote);
        }
    
        const existingVote = filteredVotes.find(voted => {
            return (
                voted.topic === vote.topic &&
                voted.topic_detail === vote.topic_detail &&
                voted.data === vote.data
            );
        });
    
        if (existingVote) {
            if (!existingVote.voters.includes(vote.voter)) {
                existingVote.voters.push(vote.voter);
            }
        } else {
            filteredVotes.push({ ...vote, voters: [vote.voter] });
        }
    
        return filteredVotes;
    }, []);

    const checkCurrent = (vote) => {
        switch(vote.topic) {
            case 'S':
                if(typeof governance.seats[vote.topic_detail] === 'undefined') return vote.data === 'rrrrrrrrrrrrrrrrrrrrrhoLvTp'
                else return governance.seats[vote.topic_detail]?.account === vote.data
            case 'R':
                switch(vote.topic_detail) {
                    case 'R':
                        return governance.state.rewardRate === vote.data
                    case 'D':
                        return governance.state.rewardDelaySeconds === vote.data
                    default:
                        return null
                }
            case 'H':
                return false
            default:
                return null
        }
    }

    governance.votes = votes.map(vote => {
        return { ...vote, current: checkCurrent(vote) }
    })
    return governance
}

export default {
    parseData,
    decimalToHex,
    xflHexToString,
    convertXflToHex
}