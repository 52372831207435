import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import VotesView from '../views/VotesView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/state',
    name: 'govState',
    component: () => import('../views/GovernanceState.vue') 
  },
  {
    path: '/votes',
    name: 'votes',
    component: VotesView
  },
  {
    path: '/votes/:account',
    name: 'votesL2',
    component: VotesView
  },
  {
    path: '/vote',
    name: 'vote',
    component: () => import('../views/VoteView.vue'),
    children: [
      {
        // todo check for error like a route guard
        path: '',
        component: () => import('../views/VotesView.vue')
      },
      {
        path: 'seat',
        component: () => import('../components/vote/VoteSeat.vue')
      },
      {
        path: 'rate',
        component: () => import('../components/vote/VoteRewardRate.vue')
      },
      {
        path: 'delay',
        component: () => import('../components/vote/VoteRewardDelay.vue')
      }
    ]
  },
  {
    path: '/vote/:id',
    name: 'voteL1',
    component: () => import('../views/VoteView.vue')
  },
  {
    path: '/vote/:account/:id',
    name: 'voteL2',
    component: () => import('../views/VoteView.vue')
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
