import { XrplClient } from 'xrpl-client'

let ws = null

const connect = (url, options) => {
  if (ws != null) return ws
  ws = new XrplClient(url, options)
  // ws = await connection.ready()
  return ws
}

const getState = () => {
  if (ws === null) return null
  else return ws.getState()
}

const ready = async () => {
  if (ws === null) return null
  else return await ws.ready()
}

const close = async () => {
  if (ws === null) return null
  const closeInfo = await ws.close()
  console.log('Closed', closeInfo)
  ws = null
}

const send = async (command) => {
  const response = await ws.send(command)
  return response
}

const on = (event, fn) => {
  if (ws === null) throw new Error('No connection')
  ws.on(event, fn)
}

const subscribeForStatus = (txid) => {
  return new Promise((resolve) => {
    on('transaction', tx => {
      if (tx.transaction.hash === txid) {
        resolve(tx)
      }
    })
  })
}

const submitTransaction = async (TxBlob) => {
  if (!TxBlob) throw new Error('tx_blob cannot be empty')

  const result = await send({
    command: 'submit',
    tx_blob: TxBlob
  })
  console.log('transaction submit result:', result)

  const txResult = result.engine_result
  if (txResult !== 'tesSUCCESS') throw txResult
  const txid = result.tx_json.hash
  return subscribeForStatus(txid)
}

export default {
  connect,
  getState,
  ready,
  close,
  send,
  on,

  subscribeForStatus,
  submitTransaction
}
