import { createStore } from 'vuex'

export default createStore({
  state: {
    initialized: false,
    governance: null,
    account: null,
    genesisAccount: 'rHb9CJAWyB4rj91VRWn96DkukG4bwdtyTh',
    networkId: null
  },
  getters: {
    getGovernanceState: (state) => (account) => {
      if(!account) return state.governance
      else {
        const layer = state.governance.seats.find(seat => seat?.account === account)
        if(!Object.hasOwn(layer || {}, 'governanceLayer')) return state.governance
        else return layer.governanceLayer
      }
    },
    getSeats: (state) => (account) => {
      if(!account || account === state.governance) return state.governance?.seats || []
      else {
        const layer = state.governance.seats.find(seat => seat?.account === account)
        if(!Object.hasOwn(layer || {}, 'governanceLayer')) return state.governance?.seats || []
        else return layer.governanceLayer.seats || []
      }
    },
    getVote: (state, getters) => (id, account) => {
      if(isNaN(id)) return null
      const votes = getters.getVotes(account)
      return votes.find(vote => vote.id === Number(id))
    },
    getVotes: (state, getters) => (account) => {
      let votes = []
      if(!state.governance) return []
      if(!account || account === state.governance) {
        votes = state.governance?.votes
      } else {
        const pos = getters.getSeatPosition(account)
        votes = state.governance.seats[pos.table].governanceLayer.votes
      }
      if(isNaN(votes?.length)) return []
      else return votes
    },
    getSeatPosition: (state) => (account) => {
      if(!account) return null
      if(!state.governance) return null
      if(!Object.hasOwn(state.governance, 'seats')) return null
      if(!state.governance.seats?.length > 0) return null

      const index = state.governance.seats.findIndex(seat => seat?.account === account)
      if(index >= 0) return { layer: 1, table: Number(state.governance.seats[index].position), l1: state.genesisAccount, l2: account }
      else {
        for(const l1 of state.governance.seats) {
          for(const l2 of l1?.governanceLayer?.seats || []) {
            if(l2.account === account) return { layer: 2, table: Number(l1.position), seat: Number(l2.position), l1: state.genesisAccount, l2: l1.account }
            else continue
          }
        }
      }
      return null
    },
    voteMode(state, getters) {
      return getters.getSeatPosition(state.account) !== null
    }
  },
  mutations: {
    setGovernance(state, payload) {
      state.governance = payload
    },
    setGovernanceLayer(state, payload) {
      const i = payload.index
      delete payload.index
      state.governance.seats[i]['governanceLayer'] = payload
    },
    setInit(state, payload) {
      state.networkId = payload.networkId
      state.account = payload.account
      state.initialized = true
    }
  },
  actions: {
    setGovernance(context, payload) {
      context.commit('setGovernance', payload)
    },
    setGovernanceLayer(context, payload) {
      if(!payload) return
      if(!payload.account) return
      const pos = context.getters.getSeatPosition(payload.account).table
      payload = { ...payload, index: pos }
      context.commit('setGovernanceLayer', payload)
    },
    initXahau(context, payload) {
      // payload = { networkId, account }
      context.commit('setInit', payload)
    }
  },
  modules: {
  }
})
